import * as React from 'react';
import { Helmet } from 'react-helmet';
import { RouteProps } from 'react-router';

import * as features from 'features';
import { ErrorBoundary } from 'services/ErrorTracking';
import { Alert } from 'services/Alert';
import { WarningModal } from 'services/Warning';
import { withAsyncFeatures } from 'core';

interface IFeatureProps {
  CabinetNoApiFactTemplate: features.CabinetNoApiFactTemplate.Entry;
}

type IProps = RouteProps & IFeatureProps;

class CabinetNoApiFactTemplate extends React.PureComponent<IProps> {
  public render() {
    const { CabinetNoApiFactTemplate } = this.props;
    return (
      <div>
        <Helmet defer={false}>
          <title>Шаблон для факта - Aizek Client Space</title>
        </Helmet>
        <ErrorBoundary>
          <Alert />
          <WarningModal />
          <CabinetNoApiFactTemplate.containers.FactTemplate />
        </ErrorBoundary>
      </div>
    );
  }
}

export default withAsyncFeatures({ CabinetNoApiFactTemplate: features.CabinetNoApiFactTemplate.loadEntry })(CabinetNoApiFactTemplate);
