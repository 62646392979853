import * as React from 'react';
import { RouteProps } from 'react-router';
import { Helmet } from 'react-helmet';

import { withAsyncFeatures } from 'core';

import * as features from 'features';

import { ErrorBoundary } from 'services/ErrorTracking';
import { Alert } from 'services/Alert';

interface IFeatureProps {
  ExportCabinet: features.ExportCabinet.Entry;
}

type IProps = RouteProps & IFeatureProps;


class SelectingRowsToExportCabinetPage extends React.PureComponent<IProps> {
  public render() {
    const { ExportCabinet } = this.props;

    return (
      <>
        <Helmet defer={false}>
          <title>Создание РК - Aizek Client Space</title>
        </Helmet>
        <ErrorBoundary>
          <Alert />
          <ExportCabinet.containers.SelectingRows />
        </ErrorBoundary>
      </>
    );
  }
}

export const SelectingRowsToExportCabinet = (
  withAsyncFeatures({ ExportCabinet: features.ExportCabinet.loadEntry })(SelectingRowsToExportCabinetPage)
);
