import * as React from 'react';
import block from 'bem-cn';
import { RouteProps } from 'react-router';
import * as features from 'features';
import { withAsyncFeatures } from 'core';

import { ErrorBoundary } from 'services/ErrorTracking';
import { Helmet } from 'react-helmet';

import './style.scss';

interface IFeatureProps {
  MarkupMediaplan: features.MarkupMediaplan.Entry;
}

type IProps = RouteProps & IFeatureProps;

const b = block('markup-mediaplan-page');

class MarkupMediaplanPageComponent extends React.PureComponent<IProps> {
  public render() {
    const { MarkupMediaplan } = this.props;

    return (
      <div className={b()}>
        <Helmet defer={false}>
          <title>Извлечение данных - Aizek Client Space</title>
        </Helmet>
        <ErrorBoundary>
          <MarkupMediaplan.containers.MarkupMediaplan />
        </ErrorBoundary>
      </div>
    );
  }
}

export const MarkupMediaplanPage = (
  withAsyncFeatures({ MarkupMediaplan: features.MarkupMediaplan.loadEntry })(
    MarkupMediaplanPageComponent
  ));
